import Vue from 'vue';
import { mapGetters } from 'vuex';
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

// Use VueSweetalert2 globally
Vue.use(VueSweetalert2)

const rules = {
  computed: {
    ...mapGetters({
      pageRights: "auth/pageRights",
      authUser: "auth/user",
    }),
    mobilePrefix() {
      return '+255';
    },
    emailRules() {
      return [
        (v) => !!v || "Enter Email",
        (v) => /.+@.+\..+/.test(v) || "Email Enterd isn't valied",
      ];
    },
    generalEmailRules() {
      return [
        (v) => {
          if (!v) return true; // Allow empty values
          return /.+@.+\..+/.test(v) || "Email entered isn't valid";
        },
      ];
    },
    mobileRules() {
      return [
        (v) => (typeof v === 'string' && v.length > 0) || "Enter Mobile",
        (v) => (/^\d+$/.test(v)) || "Mobile Number should only contain digits",
        (v) => !!v && !v.startsWith('0') || "Mobile Number should not start with 0",
        (v) => (!/\s/.test(v)) || "Mobile Number should not contain spaces",
        (v) => (!(/^\s|\s$/).test(v)) || "Mobile Number should not start or end with spaces",
        (v) => !!v && v.length === 9 || "Mobile Number should have 9 digits",
      ];
    },

    generalRules() {
      return [(v) => !!v || "Field is empty"];
    },
    passwordRules() {
      return [(v) => !!v || "Password required"];
    },
    rePassRules() {
      return [
        (v) => {
          if (this.editedItem.password && v) {
            return this.editedItem.password === v || "Passwords don't match";
          } else {
            // Allow empty passwords
            return true;
          }
        },
      ];
    },
    fileRules() {
      return [
        (v) => {
          if (!v) {
            return "File is required.";
          }
          if (v.size > 1048576) {
            return "File size exceeds the limit of 1MB";
          }
          const invalid = ["gif", "mp4", "mov", "avi"];
          const fileExtension = v.name.split(".").pop().toLowerCase();

          if (!fileExtension.match(/(jpg|jpeg|png)$/)) {
            return "Invalid file type. Only images jpg, jpeg, png are allowed.";
          }

          if (invalid.includes(fileExtension)) {
            return "Invalid file type. GIFs and videos are not allowed.";
          }

          return true;
        },
      ];
    },
    textAreaRules() {
      return [
        (v) => {
          if (!v) {
            return "Description is required.";
          }
          if (v.length > 250) {
            return "Exceeded Character limit of 250";
          }
          return true;
        },
      ];
    },
  },
  methods: {
    formatWithCommas(number) {
      const parsedNumber = parseFloat(number); // Convert the string to a number
      if (isNaN(parsedNumber)) {
        return number; // Return the input if it's not a valid number
      }
      return parsedNumber.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      });
    },
    formatFriendlyDate(dateString) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };

      const parsedDate = new Date(dateString);
      const formattedDate = parsedDate.toLocaleDateString("en-US", options);
      return formattedDate;
    },
    executeAlert({ icon = '', title = '', text = '' }) {
      Vue.swal({
        icon: icon,
        title: title,
        text: text,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Vue.swal.stopTimer;
          toast.onmouseleave = Vue.swal.resumeTimer;
        }
      });
    },
    executeWarning({ icon = '', title = '', text = '' }) {
      Vue.swal({
        icon: icon,
        title: title,
        text: text,
        confirmButtonColor: "red",
      });
    },
    executeWarningRefresh({ icon = '', title = '', text = '' }) {
      Vue.swal({
        icon: icon,
        title: title,
        text: text,
        confirmButtonColor: "red",
        confirmButtonText: "Refresh",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    },
    executeWarningWithAction({ icon = '', title = '', text = '', execute = '' }) {
      Vue.swal({
        icon: icon,
        title: title,
        text: text,
        confirmButtonColor: "#2057D9",
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonColor: "#363636",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          if (execute)
            execute();
        }
      });
    },
    capitalizeWords(str) {
      if (str)
        return str.replace(/\b[a-z]/g, (match) => match.toUpperCase());
    },
    checkRight(type) {
      let show = true;
      if(this.authUser.id!=1)
      this.pageRights.forEach((right) => {
        if (type == right.name) {
          show = right.userAccess;
          return;
        }
      });
      return show;
    },
   objectToFormData(obj) {
      const formData = new FormData();
    
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (Array.isArray(value)) {
            // If the value is an array, loop through its elements and append them separately
            for (let i = 0; i < value.length; i++) {
              const arrayKey = `${key}[${i}]`; // Create a unique key for each array element
              formData.append(arrayKey, value[i]);
            }
          } else {
            // If the value is not an array, append it directly
            formData.append(key, value);
          }
        }
      }
    
      return formData;
    }
    
  },
};
export default rules;
