import axios from "axios";
import router from "@/router";
import generalMethods from "@/generalMethods";
import Vue from 'vue';

const state = {
  gallery: {
    current_page: "",
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [{ url: "", label: "", active: false }],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  },
};
const getters = {
  galleryPerPage(state) {
    return state.gallery.per_page;
  },
  allGallery(state) {
    return state.gallery.data;
  },
  selectedSearchResult(state) {
    return state.selectedSearchResult;
  }
};
const actions = {
  async clearState({commit}){
    await commit('clearState');
  },
  async setGallery({ commit, rootGetters, dispatch }, gallery) {
    commit("loading/loading", true, { root: true });
    const formData = generalMethods.methods.objectToFormData(gallery);    
    await axios
      .post(
        process.env.VUE_APP_API + "gallery/setGallery",
        formData,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        dispatch("fetchItems");
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },

  async fetchItems(
    { commit, rootGetters, dispatch },
    obj = { search: "", perPage: "", pageNumber: "1" }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "gallery/getGallery", {
        params: {
          page: obj.pageNumber,
          perPage: obj.perPage,
          search: obj.search,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setGallery", response.data);
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async updateGallery({ commit, rootGetters, dispatch }, gallery) {
    commit("loading/loading", true, { root: true });
    const formData = generalMethods.methods.objectToFormData(gallery);    
    await axios
      .post(
        process.env.VUE_APP_API + "gallery/updateGallery",
        formData,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        commit('updateGallery', response.data.gallery);
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async deleteGallery({ commit, rootGetters, dispatch }, id) {
    commit("loading/loading", true, { root: true });
    await axios
      .delete(process.env.VUE_APP_API + "gallery/deleteGallery/" + id, {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("deleteGallery", id);
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
};
const mutations = {
  clearState(state){
    state.gallery= {
      current_page: "",
      data: [],
      first_page_url: "",
      from: 0,
      last_page: 0,
      last_page_url: "",
      links: [{ url: "", label: "", active: false }],
      next_page_url: "",
      path: "",
      per_page: 0,
      prev_page_url: "",
      to: 0,
      total: 0,
    };
  },
  setGallery(state, Gallery) {
    state.gallery = Gallery;
  },
  setSearchResults(state, suggestions) {
    state.searchResults = suggestions;
  },
  updateGallery(state, gallery) {
    let currentGalleryIndex = state.gallery.data.findIndex(r => r.id === gallery.id);
    if (currentGalleryIndex !== -1) {
      Vue.set(state.gallery.data, currentGalleryIndex, Object.assign({}, state.gallery.data[currentGalleryIndex], gallery));
    } else {
      generalMethods.methods.executeWarningRefresh({
        icon: 'warning',
        title: "Refresh Required",
        text: "Couldn't update entry on your table try to refresh your screen",
      });
    }
  },
  deleteGallery(state, id) {
    const indexToDelete = state.gallery.data.findIndex(region => region.id === id);

    if (indexToDelete !== -1) {
      // Remove the region from the array using splice
      state.gallery.data.splice(indexToDelete, 1);
    } else {
      generalMethods.methods.executeWarningRefresh({
        icon: 'warning',
        title: "Refresh Required",
        text: "Couldn't remove entry from the table try to refresh your screen",
      });
    }
  },
  // setters
  setCurrentPage(state, page) {
    state.gallery.current_page = page;
  },
  setPerPage(state, perPage) {
    state.gallery.per_page = perPage;
  },
  selectedSearchResult(state, obj) {
    state.selectedSearchResult = obj;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
