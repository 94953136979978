<template>
  <v-autocomplete
  dense
    v-model="selectedItem"
    :items="searchResults"
    :loading="isLoading"
    :search-input.sync="search"
    :label="'Search ' + store"
    item-value="id"
    item-text="name"
    return-object
    color="#2057D9"
    :prepend-icon="icon"
    :rules="strict ? generalRules : []"
  ></v-autocomplete>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["store", "collection", "strict", "dispatch","icon"],
  data() {
    return {
      search: "",
      selectedItem: null,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "loading/loading2",
    }),
    searchResults: {
      get() {
        return this.$store.state[this.store][this.collection];
      },
    },
    selected: {
      get() {
        this.selectedItem =
          this.$store.state[this.store]["selectedSearchResult"];
      },
    },
  },
  watch: {
    search(val) {
      // Items have already been requested
      if (this.loading2) return;
      this.$store.dispatch(this.store + "/search", val);
    },
    selectedItem(val) {
      this.$store.commit(this.store + "/selectedSearchResult", val);
      this.selectedItem = val;
    },
    selected(val) {
      this.selectedItem = val;
    },
  },
};
</script>
<style>
</style>