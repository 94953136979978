import axios from "axios";
import router from "@/router";
import generalMethods from "@/generalMethods";
import Vue from 'vue';

const state = {
  socials: {
    current_page: "",
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [{ url: "", label: "", active: false }],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  },
  socialOptions: [],
};
const getters = {
  socialOptions(state) {
    return state.socialOptions;
  },
  socialsPerPage(state) {
    return state.socials.per_page;
  },
  allSocials(state) {
    return state.socials.data;
  },
  selectedSearchResult(state) {
    return state.selectedSearchResult;
  }
};
const actions = {
  async clearState({ commit }) {
    await commit('clearState');
  },
  async setSocial({ commit, rootGetters, dispatch }, social) {
    commit("loading/loading", true, { root: true });
    const formData = generalMethods.methods.objectToFormData(social);
    await axios
      .post(
        process.env.VUE_APP_API + "social/setSocial",
        formData,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        dispatch("fetchItems");
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async getSocialOptions(
    { commit, rootGetters, dispatch },
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "socialOption/getSocialOptions", {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setSocialOptions", response.data);
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async fetchItems(
    { commit, rootGetters, dispatch },
    obj = { search: "", perPage: "", pageNumber: "1" }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "social/getSocials", {
        params: {
          page: obj.pageNumber,
          perPage: obj.perPage,
          search: obj.search,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setSocials", response.data);
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async updateSocial({ commit, rootGetters, dispatch }, social) {
    commit("loading/loading", true, { root: true });
    const formData = generalMethods.methods.objectToFormData(social);
    await axios
      .post(
        process.env.VUE_APP_API + "social/updateSocial",
        formData,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        commit('updateSocial', response.data.social);
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async deleteSocial({ commit, rootGetters, dispatch }, id) {
    commit("loading/loading", true, { root: true });
    await axios
      .delete(process.env.VUE_APP_API + "social/deleteSocial/" + id, {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("deleteSocial", id);
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  //search
  async search({ commit, rootGetters, dispatch }, query) {
    commit("loading/loading2", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "social/search", {
        params: {
          query: query,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setSearchResults", response.data);
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading2", false, { root: true });
      });
  },
};
const mutations = {
  clearState(state) {
    state.socials = {
      current_page: "",
      data: [],
      first_page_url: "",
      from: 0,
      last_page: 0,
      last_page_url: "",
      links: [{ url: "", label: "", active: false }],
      next_page_url: "",
      path: "",
      per_page: 0,
      prev_page_url: "",
      to: 0,
      total: 0,
    };
    state.socialOptions = [];
  },
  setSocialOptions(state, options) {
    state.socialOptions = options;
  },
  setSocials(state, Socials) {
    state.socials = Socials;
  },
  setSearchResults(state, suggestions) {
    state.searchResults = suggestions;
  },
  updateSocial(state, social) {
    let currentSocialIndex = state.socials.data.findIndex(r => r.id === social.id);
    if (currentSocialIndex !== -1) {
      Vue.set(state.socials.data, currentSocialIndex, Object.assign({}, state.socials.data[currentSocialIndex], social));
    } else {
      generalMethods.methods.executeWarningRefresh({
        icon: 'warning',
        title: "Refresh Required",
        text: "Couldn't update entry on your table try to refresh your screen",
      });
    }
  },
  deleteSocial(state, id) {
    const indexToDelete = state.socials.data.findIndex(region => region.id === id);

    if (indexToDelete !== -1) {
      // Remove the region from the array using splice
      state.socials.data.splice(indexToDelete, 1);
    } else {
      generalMethods.methods.executeWarningRefresh({
        icon: 'warning',
        title: "Refresh Required",
        text: "Couldn't remove entry from the table try to refresh your screen",
      });
    }
  },
  // setters
  setCurrentPage(state, page) {
    state.socials.current_page = page;
  },
  setPerPage(state, perPage) {
    state.socials.per_page = perPage;
  },
  selectedSearchResult(state, obj) {
    state.selectedSearchResult = obj;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
