import axios from "axios";
import router from "@/router";
import generalMethods from "@/generalMethods";
import Vue from 'vue';

const state = {
  users: {
    current_page: "",
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [{ url: "", label: "", active: false }],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  },
  userRights: {},
  selectedUser: {},
};
const getters = {
  userRights(state) {
    return state.userRights;
  },
  selectedUser(state) {
    return state.selectedUser;
  },
  usersPerPage(state) {
    return state.users.per_page;
  },
  allUsers(state) {
 return state.users.data;
  },
  selectedSearchResult(state) {
    return state.selectedSearchResult;
  }
};
const actions = {
  async clearBasic({commit}){
    await commit('clearBasic');
  },
  async clearState({commit}){
    await commit('clearState');
  },
  redirectUserRights({ commit }, user) {
    commit('selectedUser', user);
    router.push('/user_rights');
  },
  async setUser({ commit, rootGetters, dispatch }, user) {
    commit("loading/loading", true, { root: true });
    await axios
      .post(
        process.env.VUE_APP_API + "user/setUser",
        user,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,

          },
        }
      )
      .then((response) => {
        dispatch("fetchItems");
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
      });
  },
  async fetchItems(
    { commit, rootGetters, dispatch },
    obj = { search: "", perPage: "", pageNumber: "1" }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "user/getUsers", {
        params: {
          page: obj.pageNumber,
          perPage: obj.perPage,
          search: obj.search,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setUsers", response.data);
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async updateUser({ commit, rootGetters, dispatch }, user) {
    commit("loading/loading", true, { root: true });
    await axios
      .put(
        process.env.VUE_APP_API + "user/updateUser",
       user,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        dispatch("fetchItems");
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
      });
  },
  async deleteUser({ commit, rootGetters, dispatch }, id) {
    commit("loading/loading", true, { root: true });
    await axios
      .delete(process.env.VUE_APP_API + "user/deleteUser/" + id, {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("deleteUser", id);
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async search({ commit, rootGetters, dispatch }, query) {
    commit("loading/loading2", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "user/search", {
        params: {
          query: query,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setSearchResults", response.data);
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading2", false, { root: true });
      });
  },
  async fetchUserRights(
    { commit, rootGetters, dispatch },
    obj = { search: "", perPage: "", pageNumber: "1" }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "user/getUserRights", {
        params: {
          user_id: rootGetters["users/selectedUser"].id,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setUserRights", response.data);
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async setMenu({ commit, rootGetters, dispatch }, menu) {
    commit("loading/loading", true, { root: true });
    await axios
      .post(
        process.env.VUE_APP_API + "user/setMenu",
        {
          menu: menu,
          userId: rootGetters['users/selectedUser'].id,
        },
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async setMenuAction({ commit, rootGetters, dispatch }, menu) {
    commit("loading/loading", true, { root: true });
    await axios
      .post(
        process.env.VUE_APP_API + "user/setMenuAction",
        {
          menu: menu,
          userId: rootGetters['users/selectedUser'].id,
        },
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
};
const mutations = {
  clearBasic(state){
    state.users= {
      current_page: "",
      data: [],
      first_page_url: "",
      from: 0,
      last_page: 0,
      last_page_url: "",
      links: [{ url: "", label: "", active: false }],
      next_page_url: "",
      path: "",
      per_page: 0,
      prev_page_url: "",
      to: 0,
      total: 0,
    };
  },
  clearRights(state){
    state.userRights= {};
    state.selectedUser= {};
  },
  clearState(state){
    state.users= {
      current_page: "",
      data: [],
      first_page_url: "",
      from: 0,
      last_page: 0,
      last_page_url: "",
      links: [{ url: "", label: "", active: false }],
      next_page_url: "",
      path: "",
      per_page: 0,
      prev_page_url: "",
      to: 0,
      total: 0,
    };
    state.userRights= {};
    state.selectedUser= {};
  },
  setUsers(state, Users) {
    state.users = Users;
  },
  selectedUser(state, user) {
    state.selectedUser = user;
  },
  setUserRights(state, rights) {
    state.userRights = rights;
  },
  setSearchResults(state, suggestions) {
    state.searchResults = suggestions;
  },
  updateUser(state, product) {
    let currentUserIndex = state.users.data.findIndex(r => r.id === product.id);
    if (currentUserIndex !== -1) {
      Vue.set(state.users.data, currentUserIndex, Object.assign({}, state.users.data[currentUserIndex], product));
    } else {
      generalMethods.methods.executeWarningRefresh({
        icon: 'warning',
        title: "Refresh Required",
        text: "Couldn't update entry on your table try to refresh your screen",
      });
    }
  },
  deleteUser(state, id) {
    const indexToDelete = state.users.data.findIndex(product => product.id === id);

    if (indexToDelete !== -1) {
      // Remove the product from the array using splice
      state.users.data.splice(indexToDelete, 1);
    } else {
      generalMethods.methods.executeWarningRefresh({
        icon: 'warning',
        title: "Refresh Required",
        text: "Couldn't remove entry from the table try to refresh your screen",
      });
    }
  },
  // setters
  setCurrentPage(state, page) {
    state.users.current_page = page;
  },
  setPerPage(state, perPage) {
    state.users.per_page = perPage;
  },
  selectedSearchResult(state, obj) {
    state.selectedSearchResult = obj;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
