<template>
  <v-card flat color="transparent" width="100%" class="mx-auto" @keyup.enter="submit">
    <!-- bagining of signin form -->
    <v-form ref="signinForm">
      <v-card-text>
        <v-text-field
          v-model="user.username"
          color="black"
          label="username"
          prepend-icon="mdi mdi-account"
          :rules="generalRules"
          ref="usernameInput"
        ></v-text-field>
        <v-text-field
          v-model="user.password"
          label="Password"
          color="black"
          prepend-icon="mdi mdi-form-textbox-password"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
          :rules="passwordRules"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mr-3 white--text" @click="submit" color="#2057D9">
          Signin
        </v-btn>
        <v-btn @click="clear">clear</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      show: false,
      error: null,
      loading: false,
    };
  },
  methods: {
    ...mapActions({ signin: "auth/signin" }),
    clear() {
      this.$refs.signinForm.reset();
    },
    async submit() {
      if (this.$refs.signinForm.validate()) {
        this.signin(this.user);
      }
    },
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
    }),
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.usernameInput.focus();
    });
  },
};
</script>

<style>
</style>
