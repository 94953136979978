import axios from "axios";
import router from "@/router";
import generalMethods from "@/generalMethods";
const state = {
  user: {
    id: "",
    name: "",
    email: "",
    email_verified_at: "",
    createdby: "",
    status: "",
    remember_token: "",
    created_at: "",
    updated_at: "",
  },
  rights: {},
  token: "",
  authenticated: false,
};
const getters = {
  pages(state) {
    let pages = [];
    state.rights.forEach((page) => {
      // Clone the page object
      const clonedPage = { ...page };

      // Remove the 'actions' key from the cloned page object
      delete clonedPage.actions;

      // Push the modified page object into the 'pages' array
      pages.push(clonedPage);
    });
    return pages;
  },

  pageRights(state) {
    let pageRights = null;
    state.rights.forEach((page) => {
      if (page.name === router.currentRoute.name) {
        pageRights = page.actions;
      }
    });

    if (!pageRights) {
      // Handle the case where no matching page was found
      console.warn(`No page rights found for route: ${router.currentRoute.name}`);
      return;
    }
    return pageRights;
  },
  user(state) {
    return state.user;
  },
  rights(state) {
    return state.rights;
  },
  userName(state) {
    return state.user.name;
  },
  authenticated(state) {
    return state.authenticated;
  },
  token(state) {
    return state.token;
  },
};
const actions = {
  async signin({ commit, dispatch, getters }, user) {
    commit("loading/loading", true, { root: true });
    await axios
      .post(process.env.VUE_APP_API + "signin", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
        commit("setUser", response.data);
        commit("setAuthenticated", true);


        const userPages = getters.pages;

        // Find the first page with userAccess set to true
        const firstAccessiblePage = userPages.find(page => page.userAccess);
        if (getters.user.id != 1) {
          if (firstAccessiblePage) {
            // Redirect to the page using route name
            router.push({ name: firstAccessiblePage.name });
          } else {
            // Alert the user that they need to request admin for access
            generalMethods.methods.executeWarning({
              icon: 'warning',
              title: 'No permissions',
              text: "You need to request admin for access to the system pages.  Currently you have no access to any of them."
            });

          }
        } else {
          router.push({ name: 'users' });
        }

      })
      .catch((error) => {
        console.error(error);
        dispatch('handleErrors', error);
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  signout({ commit, getters, dispatch }) {
    commit("loading/loading", true, { root: true });
    axios
      .get(process.env.VUE_APP_API + "signout", {
        headers: {
          Authorization: "Bearer " + getters.token,
          "x-user-id": getters.user.id,
        },
      })
      .then((response) => {
        dispatch('composite/clearAllStates',null,{root:true});
        router.push("/");
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('handleErrors', error);
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
      });
  },
  handleErrors({ dispatch }, error) {
    if (error.response && error.response.data && "error" in error.response.data) {
      generalMethods.methods.executeAlert({
        icon: 'error',
        title: 'Error',
        text: error.response.data.error,
      });
    } else {
      generalMethods.methods.executeAlert({
        icon: 'error',
        title: 'Error',
        text: "Something went wrong",
      });
    }

    if (error.response && 'exit' in error.response.data && error.response.data.exit == 1) {
      dispatch('auth/signout');
    }

    if (error.response && (error.response.status === 401 || error.response.status === 403) && !('exit' in error.response.data)) {
      dispatch('forbiddenError');
    }
  },
  forbiddenError({ commit,dispatch }) {
    commit("setAuthenticated", false);
    if (router.currentRoute.name != 'signin')
      router.push("/");
    dispatch('composite/clearAllStates',null,{root:true});
    generalMethods.methods.executeWarning({
      icon: 'warning',
      title: 'Re-authenticate',
      text: "Something went wrong re-authenticate!!"
    });
  }
};
const mutations = {
  setUser(state, data) {
    state.user = data.user;
    state.token = data.token;
    state.rights = data.rights;
  },
  setAuthenticated(state, value) {
    state.authenticated = value;
  },

  clearState(state) {
    // Clear user module rootStates
    state.authenticated = false;
    state.user = {
      id: "",
      name: "",
      email: "",
      email_verified_at: "",
      createdby: "",
      status: "",
      remember_token: "",
      created_at: "",
      updated_at: "",
    };
    state.token = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
