import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[(_vm.show)?_c('sideNav'):_vm._e(),_c(VMain,[_c(VSlideXTransition,{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1),_c(VOverlay,{attrs:{"value":_vm.loading,"z-index":1000}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }