import axios from "axios";
import router from "@/router";
import generalMethods from "@/generalMethods";
import Vue from 'vue';

const state = {
  articles: {
    current_page: "",
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [{ url: "", label: "", active: false }],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  },
};
const getters = {
  articlesPerPage(state) {
    return state.articles.per_page;
  },
  allArticles(state) {
    return state.articles.data;
  },
  selectedSearchResult(state) {
    return state.selectedSearchResult;
  }
};
const actions = {
  async clearState({commit}){
    await commit('clearState');
  },
  async setArticle({ commit, rootGetters, dispatch }, article) {
    commit("loading/loading", true, { root: true });
    const formData = generalMethods.methods.objectToFormData(article);    
    await axios
      .post(
        process.env.VUE_APP_API + "article/setArticle",
        formData,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        dispatch("fetchItems");
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },

  async fetchItems(
    { commit, rootGetters, dispatch },
    obj = { search: "", perPage: "", pageNumber: "1" }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "article/getArticles", {
        params: {
          page: obj.pageNumber,
          perPage: obj.perPage,
          search: obj.search,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setArticles", response.data);
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async updateArticle({ commit, rootGetters, dispatch }, article) {
    commit("loading/loading", true, { root: true });
    const formData = generalMethods.methods.objectToFormData(article);    
    await axios
      .post(
        process.env.VUE_APP_API + "article/updateArticle",
        formData,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        commit('updateArticle', response.data.article);
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async deleteArticle({ commit, rootGetters, dispatch }, id) {
    commit("loading/loading", true, { root: true });
    await axios
      .delete(process.env.VUE_APP_API + "article/deleteArticle/" + id, {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("deleteArticle", id);
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  //search
  async search({ commit, rootGetters, dispatch }, query) {
    commit("loading/loading2", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "article/search", {
        params: {
          query: query,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setSearchResults", response.data);
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading2", false, { root: true });
      });
  },
};
const mutations = {
  clearState(state){
    state.articles= {
      current_page: "",
      data: [],
      first_page_url: "",
      from: 0,
      last_page: 0,
      last_page_url: "",
      links: [{ url: "", label: "", active: false }],
      next_page_url: "",
      path: "",
      per_page: 0,
      prev_page_url: "",
      to: 0,
      total: 0,
    };
  },
  setArticles(state, Articles) {
    state.articles = Articles;
  },
  setSearchResults(state, suggestions) {
    state.searchResults = suggestions;
  },
  updateArticle(state, article) {
    let currentArticleIndex = state.articles.data.findIndex(r => r.id === article.id);
    if (currentArticleIndex !== -1) {
      Vue.set(state.articles.data, currentArticleIndex, Object.assign({}, state.articles.data[currentArticleIndex], article));
    } else {
      generalMethods.methods.executeWarningRefresh({
        icon: 'warning',
        title: "Refresh Required",
        text: "Couldn't update entry on your table try to refresh your screen",
      });
    }
  },
  deleteArticle(state, id) {
    const indexToDelete = state.articles.data.findIndex(region => region.id === id);

    if (indexToDelete !== -1) {
      // Remove the region from the array using splice
      state.articles.data.splice(indexToDelete, 1);
    } else {
      generalMethods.methods.executeWarningRefresh({
        icon: 'warning',
        title: "Refresh Required",
        text: "Couldn't remove entry from the table try to refresh your screen",
      });
    }
  },
  // setters
  setCurrentPage(state, page) {
    state.articles.current_page = page;
  },
  setPerPage(state, perPage) {
    state.articles.per_page = perPage;
  },
  selectedSearchResult(state, obj) {
    state.selectedSearchResult = obj;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
