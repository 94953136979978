import axios from 'axios';

const state={
    loading:false,
    loading2:false,
    
};
const getters={
    loading(state){
        return state.loading;
    },
    loading2(state){
        return state.loading2;
    },
};
const actions={};
const mutations={
    loading(state,data){
        return state.loading=data;
    },
    loading2(state,data){
        return state.loading2=data;
    },
};

export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}