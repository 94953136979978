import Vue from 'vue'
import VueRouter from 'vue-router'
import signin from "../views/signin.vue";
import store from "@/store";
import generalMethods from "@/generalMethods";


Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "signin",
    component: signin,
    meta: {
      requiresAuth: false,
      pageTitle: "signin",
    }
  },
  {
    path: "/users",
    name: "users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/users.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "system users",
    },
  },
  {
    path: "/user_rights",
    name: "rights",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/user_rights.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "user rights",
    },
  },
  {
    path: "/articles",
    name: "articles",
    component: () =>
      import(/* webpackChunkName: "articles" */ "../views/articles.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "articles",
    },
  },
  {
    path: "/videos",
    name: "videos",
    component: () =>
      import(/* webpackChunkName: "videos" */ "../views/videos.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "videos",
    },
  },
  {
    path: "/audio",
    name: "audio",
    component: () =>
      import(/* webpackChunkName: "audio" */ "../views/audio.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "audio",
    },
  },
  {
    path: "/books",
    name: "books",
    component: () =>
      import(/* webpackChunkName: "books" */ "../views/books.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "books",
    },
  },
  {
    path: "/socials",
    name: "socials",
    component: () =>
      import(/* webpackChunkName: "company" */ "../views/socials.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "socials",
    },
  },
  {
    path: "/company_info",
    name: "company_information",
    component: () =>
      import(/* webpackChunkName: "company" */ "../views/company_info.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "about sheikh",
    },
  },
  {
    path: "/gallery",
    name: "gallery",
    component: () =>
      import(/* webpackChunkName: "gallery" */ "../views/gallery.vue"),
    meta: {
      requiresAuth: true,
      pageTitle: "gallery",
    },
  },
];


const router = new VueRouter({
  routes
});

// Define the handleBackButton function
const handleBackButton = () => {
  router.beforeEach((to, from, next) => {
    if (window.event.type === 'popstate' && from.name === 'signin') {
      next(false);
    } else {
      next();
    }
  });
};
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters["auth/authenticated"]) {
      const userId = store.getters['auth/user'].id;

      // If the user is the superuser, grant access directly
      if (userId === 1) {
          next();
      } else {
        // Check if the user has access to the requested route
        const userPages = store.getters['auth/pages'];
        const hasAccess = userPages.some(page => page.name === to.name && page.userAccess);
        const firstAccessiblePage = userPages.find(page => page.userAccess);

        if (hasAccess) {
            next();
        } else {
          generalMethods.methods.executeAlert({
            icon: 'error',
            title: 'Error',
            text: "You don't have access to this page.",
          });

          // Find the first page with userAccess set to true
          if (firstAccessiblePage) {
            // Redirect to the page using route name
            next({ name: firstAccessiblePage.name });
          } else {
            // Alert the user that they need to request admin for access
            generalMethods.methods.executeWarning({
              icon: 'warning',
              title: 'No permissions!',
              text: "You need to request admin for access to the system pages. Currently you have no access to any of them."
            });
          }
        }
      }
    } else {
      next({ name: "signin" });
      generalMethods.methods.executeAlert({
        icon: 'error',
        title: 'Error',
        text: "You need to be authenticated.",
      });
    }
  } else {
    // Check if the page exists in your application routes
    const pageExists = router.options.routes.some(route => route.name === to.name);
    if (pageExists) {
      next();
    } else {
      generalMethods.methods.executeAlert({
        icon: 'error',
        title: 'Error',
        text: "Page doesn't exist.",
      });
    }
  }
});






export default router
