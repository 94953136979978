import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import generalMethods from './generalMethods';
import pagninate from './components/pagninate.vue';
import search_autocomplete from './components/search_autocomplete.vue';
import Vue2Editor from "vue2-editor";
import {MdChips} from 'vue-material/dist/components'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

Vue.use(MdChips)
Vue.use(Vue2Editor);
Vue.mixin(generalMethods);
Vue.component('pagninate', pagninate);
Vue.component('searchAutocomplete', search_autocomplete);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
