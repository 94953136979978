import axios from "axios";
import generalMethods from "@/generalMethods";
const state = {
  info: {},
};
const getters = {
  info(state) {
    return state.info;
  },
};
const actions = {
  async clearState({commit}){
    await commit('clearState');
  },
  async setCompanyInfo({ commit, rootGetters, dispatch }, companyInfo) {
    commit("loading/loading", true, { root: true });
    const formData = generalMethods.methods.objectToFormData(companyInfo);    
    await axios
      .post(
        process.env.VUE_APP_API + "companyInfo/setCompanyInfo",
        formData,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        dispatch('getCompanyInfo');
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });
      });
  },
  async getCompanyInfo(
    { commit, rootGetters, dispatch },
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "companyInfo/getCompanyInfo", {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setInfo", response.data);
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async updateCompanyInfo({ commit, rootGetters, dispatch }, companyInfo) {
    commit("loading/loading", true, { root: true });
    const formData = generalMethods.methods.objectToFormData(companyInfo);    
    await axios
      .post(
        process.env.VUE_APP_API + "companyInfo/updateCompanyInfo",
        formData,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        dispatch('getCompanyInfo');
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
};
const mutations = {
  clearState(state){
    state.info= {};
  },
  setInfo(state, info) {
    state.info = info;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
