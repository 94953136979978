<template>
  <v-row>
    <v-col cols="6">
      <v-card class="mx-auto mt-15" width="80%" height="100%" flat>
        <v-card-title class="font-weight-bold black--text">
          <v-card color="#2057D9" class="mx-auto">
            <!-- <v-img
              src="@/assets/logo-transparent-text.png"
              lazy-src="@/assets/logo-transparent-text.png"
              class="ma-1"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="#2057D9"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img> -->
          </v-card>
          <br /><br />
        </v-card-title>
        <v-card-text style="font-size: 20px">
          <span class="black--text"></span>
          <br /><span style="font-size: 15px !important">
            <span class="black--text"></span
          ></span>
        </v-card-text>
        <signinForm />
      </v-card>
    </v-col>
    <v-col>
      <v-img
        src="@/assets/logo-transparent.png"
        lazy-src="@/assets/logo-transparent.png"
        width="400px"
        class="mx-auto mt-15"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="#2057D9"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
import signinForm from "./signinForm.vue";
export default {
  data() {
    return {};
  },
  components: { signinForm },
};
</script>

<style scoped>
</style>