import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import users from "./modules/users";
import videos from "./modules/videos";
import articles from "./modules/articles";
import audio from "./modules/audio";
import books from "./modules/books";
import socials from "./modules/socials";
import company_info from "./modules/company_info";
import composite from "./modules/composite";
import loading from "./modules/loading";
import gallery from "./modules/gallery";


import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  //storing session state incase user refreshes page user token is not lost
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
  modules: {
    auth,
    loading,
    users,
    videos,
    articles,
    books,
    audio,
    socials,
    company_info,
    composite,
    gallery,
  },
});
