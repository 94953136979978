import axios from "axios";
import router from "@/router";
import generalMethods from "@/generalMethods";
import Vue from 'vue';

const state = {
  videos: {
    current_page: "",
    data: [],
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [{ url: "", label: "", active: false }],
    next_page_url: "",
    path: "",
    per_page: 0,
    prev_page_url: "",
    to: 0,
    total: 0,
  },
  searchResults: [],
  selectedSearchResult: {},
};
const getters = {
  videosPerPage(state) {
    return state.videos.per_page;
  },
  allVideos(state) {
 return state.videos.data;
  },
  selectedSearchResult(state) {
    return state.selectedSearchResult;
  }
};
const actions = {
  async clearState({commit}){
    await commit('clearState');
  },
  async setVideo({ commit, rootGetters, dispatch }, video) {
    commit("loading/loading", true, { root: true });
    const formData = generalMethods.methods.objectToFormData(video);
    await axios
      .post(
        process.env.VUE_APP_API + "video/setVideo",
        formData,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        dispatch("fetchItems");
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },

  async fetchItems(
    { commit, rootGetters, dispatch },
    obj = { search: "", perPage: "", pageNumber: "1" }
  ) {
    commit("loading/loading", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "video/getVideos", {
        params: {
          page: obj.pageNumber,
          perPage: obj.perPage,
          search: obj.search,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setVideos", response.data);
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async updateVideo({ commit, rootGetters, dispatch }, video) {
    commit("loading/loading", true, { root: true });
    const formData = generalMethods.methods.objectToFormData(video);
    await axios
      .post(
        process.env.VUE_APP_API + "video/updateVideo",
        formData,
        {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/token"],
            "x-user-id": rootGetters["auth/user"].id,
          },
        }
      )
      .then((response) => {
        commit('updateVideo', response.data.video);
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });

      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  async deleteVideo({ commit, rootGetters, dispatch }, id) {
    commit("loading/loading", true, { root: true });
    await axios
      .delete(process.env.VUE_APP_API + "video/deleteVideo/" + id, {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("deleteVideo", id);
        generalMethods.methods.executeAlert({
          icon: 'success',
          title: 'Success',
          text: response.data.message
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading", false, { root: true });

      });
  },
  //search
  async search({ commit, rootGetters, dispatch }, query) {
    commit("loading/loading2", true, { root: true });
    await axios
      .get(process.env.VUE_APP_API + "video/search", {
        params: {
          query: query,
        },
        headers: {
          Authorization: "Bearer " + rootGetters["auth/token"],
          "x-user-id": rootGetters["auth/user"].id,
        },
      })
      .then((response) => {
        commit("setSearchResults", response.data);
      })
      .catch((error) => {
        console.error(error);
        dispatch('auth/handleErrors', error, { root: true });
      })
      .finally(() => {
        commit("loading/loading2", false, { root: true });
      });
  },
};
const mutations = {
  clearState(state){
    state.videos= {
      current_page: "",
      data: [],
      first_page_url: "",
      from: 0,
      last_page: 0,
      last_page_url: "",
      links: [{ url: "", label: "", active: false }],
      next_page_url: "",
      path: "",
      per_page: 0,
      prev_page_url: "",
      to: 0,
      total: 0,
    };
    state.searchResults= [];
    state.selectedSearchResult= {};
  },
  setVideos(state, Videos) {
    state.videos = Videos;
  },
  setSearchResults(state, suggestions) {
    state.searchResults = suggestions;
  },
  updateVideo(state, video) {
    let currentVideoIndex = state.videos.data.findIndex(r => r.id === video.id);
    if (currentVideoIndex !== -1) {
      Vue.set(state.videos.data, currentVideoIndex, Object.assign({}, state.videos.data[currentVideoIndex], video));
    } else {
      generalMethods.methods.executeWarningRefresh({
        icon: 'warning',
        title: "Refresh Required",
        text: "Couldn't update entry on your table try to refresh your screen",
      });
    }
  },
  deleteVideo(state, id) {
    const indexToDelete = state.videos.data.findIndex(region => region.id === id);

    if (indexToDelete !== -1) {
      // Remove the region from the array using splice
      state.videos.data.splice(indexToDelete, 1);
    } else {
      generalMethods.methods.executeWarningRefresh({
        icon: 'warning',
        title: "Refresh Required",
        text: "Couldn't remove entry from the table try to refresh your screen",
      });
    }
  },
  // setters
  setCurrentPage(state, page) {
    state.videos.current_page = page;
  },
  setPerPage(state, perPage) {
    state.videos.per_page = perPage;
  },
  selectedSearchResult(state, obj) {
    state.selectedSearchResult = obj;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
