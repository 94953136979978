import axios from 'axios';

const state = {};
const getters = {};
const actions = {
    async clearAllStates({ dispatch }) {
        dispatch('articles/clearState', null, { root: true });
        dispatch('audio/clearState', null, { root: true });
        dispatch('books/clearState', null, { root: true });
        dispatch('company_info/clearState', null, { root: true });
        dispatch('socials/clearState', null, { root: true });
        dispatch('users/clearState', null, { root: true });
        dispatch('auth/clearState', null, { root: true });
    }
};
const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}